.ui[class*='bottom attached'].menu {
  margin-bottom: 0rem !important;
}
.ui.menu {
  display: flex;
  margin: 1rem 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
  border: 0px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border-radius: 0rem !important;
  min-height: 2.85714286em;
  background: linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%) !important;
}
